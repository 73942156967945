.errored {
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 20px;

  & > * {
    margin-block-start: 0px;
    margin-block-end: 0px;
  }
}

.iconContainer {
  margin-bottom: 20px;
}

.heading {
  color: #000;
  line-height: 1.33;
  font-size: 24px;
  font-weight: 600;
}

.message {
  color: #000;
  margin-top: 4px;
  line-height: 2;
  font-size: 16px;
  font-weight: 500;
}

.details {
  color: #000;
  margin-top: 15px;
  line-height: 1.5;
  font-size: 16px;

  a {
    color: #0087dd;
    text-decoration: none;
  }
}

.buttonContainer {
  margin-top: 25px;
}

.requestId {
  margin-top: 20px;
  color: #6d6d77;
  font-size: 10px;
}

.technicalDetails {
  margin-top: 20px;

  p {
    color: #6d6d77;
    line-height: 0.5;
    font-size: 12px;
  }

  pre {
    padding: 8px;
    background: #eee;
    text-align: left;
    overflow-y: auto;
    max-height: 20vh;
    white-space: pre-wrap;
    margin: 0 5px;
  }
}

.cssModuleFailTip {
  li {
    text-align: left;
    color: #000;
    font-weight: bold;
  }
  p {
    width: 100%;
    text-align: left;
  }
  .hardReloadTip {
    width: 300px;
    margin-bottom: 20px;
  }
}

@primary-color: #464ac9;@border-radius-base: 4px;@error-color: #dc5758;